<template>
  <!-- 首页 -->
  <div class="box">
    <!-- 头部 -->
    <div class="logoTop">
      <span><img src="../../assets/anluoyun.png" alt="" style="width:13%" /></span>
      <span @click="viewList()"><van-icon name="bars" /></span>
    </div>
    <van-popup
      v-model="tabShow"
      class="tabShow"
      position="right"
      :style="{ height: '100%' }"
    >
      <div style="margin: 15px">
        <img src="../../assets/anluoyun.png" alt="" style="width:80%;margin:0 10%">
      </div>
      <ul>
        <li><router-link to="/anLuoYun">网站首页</router-link></li>
        <li class="select">
          <router-link to="/anLuoYun/about">关于我们</router-link>
        </li>
        <li><router-link to="/anLuoYun/serve">企业项目</router-link></li>
        <li><router-link to="/anLuoYun/recruit">企业招聘</router-link></li>
        <li><router-link to="/anLuoYun/message">企业资讯</router-link></li>
        <li><router-link to="/anLuoYun/contact">联系我们</router-link></li>
      </ul>
    </van-popup>
    <!-- 标题 -->
    <div class="title">关于我们</div>
    <!-- 内容 -->
    <article class="center">
      本公司位于洛阳市洛龙区，是一家专注于互联网系统软件开发以及定制开发的企业。基于PC端、微信公众号、小程序、H5移动网页等端口，提供智慧社区系统、智慧物业系统等开发服务，以及各种大数据展示模式定制开发服务。<br />
      本公司拥有强大的服务团队，多年行业经验，资深产品经理全程1对1服务。丰富的系统开发经验保证系统安全、数据信息安全、长期运行稳定，让用户体验更优秀。截止目前，长期合作客户覆盖社区管理、物业、医疗等多个行业。<br />
      其中，互联网科技的核心产品——智慧社区系统已经升级至3.0版本。系统从数据、管理、多态和展示4个维度出发，设计了智慧党建、工作管理、掌上服务、人口卡片、智慧养老、数据统计、社区机构等模块，帮助社区更高效地管理社区数据和日常工作。<br />
      从模式需求对接&gt;原型设计&gt;页面设计&gt;前端切图&gt;技术实现&gt;测试&gt;上线&gt;售后维护，全套服务，解决您的后顾之忧。
    </article>
    <!-- 底部 -->
    <aside class="buttonVal">
      <div>电 话：18837992186</div>
      <div>地 址：洛阳市经济技术开发区长夏门街32号863创智广场1 幢2201</div>
      <div>版 权：河南安洛云信息科技有限公司</div>
      <div>备案号：豫ICP备17050318号-1</div>
    </aside>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabShow: false,
    };
  },
  methods: {
    // 顶部tab菜单显示
    viewList() {
      if (this.tabShow == true) {
        this.tabShow = false;
      } else {
        this.tabShow = true;
      }
    },
    pushTo(row) {},
  },
};
</script>
<style scoped>
.logoTop {
  position: fixed;
  top: 0;
  width: 100%;
  height: 65px;
  background: #fff;
  box-shadow: 0px 1px 5px #ccc;
}
.logoTop span:nth-child(1) {
  position: absolute;
  left: 15px;
  top: 10px;
}
.logoTop span:nth-child(2) {
  position: absolute;
  right: 15px;
  top: 13px;
  font-size: 37px;
}
.tabShow {
  width: 130px;
}
.tabShow li {
  padding: 8px 0px;
  text-align: center;
  color: #000;
}
.tabShow a {
  color: #000;
}
.tabShow .select {
  background: #ccc;
}
.tabShow .select a {
  color: #0076f6;
}
.title {
  margin: 90px 15px 0 15px;
  font-size: 18px;
  color: #333;
  padding-bottom: 10px;
  border-bottom: 2px solid #2196f3;
}
.center {
  height: 58%;
  margin-top: 8px;
  margin-bottom: 30px;
  padding: 0px 15px 30px 15px;
  font-size: 15px;
  line-height: 30px;
  overflow-y: auto;
}
.buttonVal {
  width: 100%;
  height: 18%;
  position: fixed;
  bottom: 0px;
  padding: 8px 15px;
  background: #333333;
  font-size: 14px;
  color: #fff;
}
.buttonVal div {
  margin: 5px;
}
.child-view {
  height: 100%;
}
</style>